<template>
    <ModalTemplate modalID="sentReset">
        <div class="modal fade" id="sentReset" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header p-3 p-md-4">
                        <h3 class="modal-title text-center w-100">Password Reset Email Sent</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-3 p-md-5">

                        <img class="modal-icon mb-5" src="@/assets/img/Envelope.png" alt="">
                        <p class="text-secondary text-center mb-4 lh-lg">
                            An email has been sent to your mailbox at <br>
                            <span class="text-dark fw-bold">{{email}}</span><br>
                            Please follow the instructions there to reset your password.
                        </p>
                        <button class="btn btn-lg btn-main-1 text-white w-100" data-bs-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </ModalTemplate>
</template>

<script>
import ModalTemplate from "@/components/modals/ModalTemplate.vue";
import { getCookie , deleteCookie} from '@/assets/js/common.js';

export default {
    name: 'sentReset',
    components: {
		ModalTemplate,
	},
    data(){
        return{
            email:""
        }
    },
    mounted() {

        let sentReset = document.getElementById('sentReset');
        sentReset.addEventListener('show.bs.modal',() => {
            this.email = getCookie('tempEmail') || "";
            deleteCookie('tempEmail')
        })
    },
}
</script>