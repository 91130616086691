<template>
    <ModalTemplate modalID="signupSuccess">
        <div class="modal fade" id="signupSuccess" tabindex="-1">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header p-3 p-md-4">
                        <h3 class="modal-title text-center w-100">Email Address Verification</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-3 p-md-5 text-center">
                        <img width="200" src="@/assets/img/BinaryFlash-blue.png" alt="">
                        <p class="text-success mt-2">Thank you for signing up BinaryFlash.</p>
                        <p class="text-secondary mb-4 lh-lg">
                            An email has been sent to your mailbox at <br>
                            <span class="text-dark fw-bold">{{email}}</span><br>
                            Please follow the instructions there to verify its address.
                        </p>
                        <button class="btn btn-lg btn-main-1 text-white w-100" data-bs-dismiss="modal" >OK</button>
                    </div>

                </div>
            </div>
        </div>
    </ModalTemplate>
</template>

<script>
import ModalTemplate from "@/components/modals/ModalTemplate.vue";
import { getCookie , deleteCookie } from '@/assets/js/common.js';

export default {
    name: 'signupSuccess',
    data(){
        return{
            email:""
        }
    },
    components: {
		ModalTemplate,
	},
    mounted() {

        let signupSuccess = document.getElementById('signupSuccess');
        signupSuccess.addEventListener('show.bs.modal',() => {
            this.email = getCookie('tempEmail') || "";
            deleteCookie('tempEmail')
        })
    },

}
</script>