<template>
  <footer id="footer">
      <div class="container">
          <ul class="nav flex-column flex-md-row justify-content-around align-items-center mb-5">
				<li class="nav-item">
					<router-link class="nav-link text-white" :to="{ name: 'About' }">About</router-link>
				</li>
				<li class="nav-item">
					<router-link class="nav-link text-white" :to="{ name: 'Plan' }">Plan</router-link>
				</li>
				<li class="nav-item">
					<router-link class="nav-link text-white" :to="{ name: 'Contact' }">Contact Us</router-link>
				</li>
				<li class="nav-item">
					<router-link class="nav-link text-white" :to="{ name: 'Terms' }">Terms</router-link>
				</li>
			</ul>

            <p class="text-center text-muted mb-0">BinaryFlash® 2021 COPY RIGHT</p>
      </div>

  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss" scoped>
    @import "@/scss/all.scss";

    #footer{
		background-color: $main-3;

        @include media-breakpoint-up(md) { 
			padding: 3rem 0;
		}

        @include media-breakpoint-down(md) { 
			padding: 2rem 0;
		}
    }
    
    ul{
        list-style: none;
        
    }
	
</style>
