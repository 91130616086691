<template>
  <div v-if="pageLoading" class="page-loading"></div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'PageLoading',
  computed:{
    ...mapState(['pageLoading'])
  }
}
</script>

<style lang="scss" scoped>
    @import "@/scss/all.scss";

    .page-loading{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #00000030;
        z-index: 9999;
	}
	
</style>
