export function setCookie(name, value, ms) {
    let expires = "";
    if (ms) {
        let date = new Date();
        date.setTime(date.getTime() + ms);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i = 0 ; i < ca.length ; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function deleteCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getUrlParams(key) {
    let currentUrl = window.location.href;
    let url = new URL(currentUrl);
    let value = url.searchParams.get(key);
    return value;
}

export function removeUrlParams() {
    let url= document.location.href;
    window.history.pushState({}, "", url.split("?")[0]);
}

export function getErrorMessage(message){

    let errorMsg = "";
    let errorObj = {};

    let index = message.indexOf('{')

    if(index >= 0){
        errorMsg = message.substring(0, index - 1);
        errorObj = message.substring(index - 1, message.length);
        errorObj = JSON.parse(errorObj)
    
    }else{
        errorMsg = message;
    }

    return {
        errorMsg,
        errorObj
    };
}

export function formatShowDate(date) {
    // 2021-07-21 to July 21, 2021
    if(!date){
        return "";
    }

    const monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    let arrayDate = date.split('-');
    let year = arrayDate[0];
    let month = monthName[parseInt(arrayDate[1]) - 1];
    let day = arrayDate[2];

    return `${month} ${day}, ${year}`;
}