<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
import { mapState , mapMutations } from 'vuex'
import { Modal } from 'bootstrap'

export default {
    name: 'modal',
    props:{
        modalID:{
            required:true
        }
    },
    computed:{
        ...mapState(['modals'])
    },
    methods:{
        ...mapMutations(['set_modal']),
    },
    mounted() {
        this.set_modal({
            modalName: this.modalID,
            modalValue: new Modal(document.getElementById(this.modalID))
        })
        
    },
    
}
</script>