<template>
	<div id="app">
		<Navbar></Navbar>
		<main class="page-body">
			<router-view />
		</main>
		<Footer></Footer>
		<Login></Login>
		<Signup></Signup>
		<SignupSuccess></SignupSuccess>
		<EmailVerified></EmailVerified>
		<EmailVerification></EmailVerification>
		<ResetPassword></ResetPassword>
		<ForgetPassword></ForgetPassword>
		<SentReset></SentReset>

		<PageLoading></PageLoading>
	</div>
</template>

<script>
import Navbar from "@/components/layouts/Navbar.vue";
import Footer from "@/components/layouts/Footer.vue";
import PageLoading from "@/components/layouts/PageLoading.vue";
import Login from "@/components/modals/Login.vue";
import Signup from "@/components/modals/Signup.vue";
import SignupSuccess from "@/components/modals/SignupSuccess.vue";
import EmailVerified from "@/components/modals/EmailVerified.vue";
import EmailVerification from "@/components/modals/EmailVerification.vue";
import ResetPassword from "@/components/modals/ResetPassword.vue";
import ForgetPassword from "@/components/modals/ForgetPassword.vue";
import SentReset from "@/components/modals/SentReset.vue";

import { mapActions , mapState } from 'vuex';
import { getCookie } from '@/assets/js/common.js';

export default {
	name: "Home",
	components: {
		Navbar,
		Footer,
		PageLoading,
		Login,
		Signup,
		SignupSuccess,
		EmailVerified,
		EmailVerification,
		ResetPassword,
		ForgetPassword,
		SentReset,

	},
	computed:{
        ...mapState(['modals'])
    },
	methods:{
		...mapActions(['getMember'])
	},
	mounted() {

		let token = getCookie('token');
		if(token){
			this.getMember();
		}

	},
};
</script>

<style lang="scss">
	@import "@/scss/all.scss";
	body {
		background-image: url(~@/assets/img/Img_BG_01.png);
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.page-body {
		overflow-x: hidden;
		min-height: calc(100vh - 56px);

		@include media-breakpoint-up(lg) {
			margin-top: 56px;
			padding-top: 6rem;
			padding-bottom: 6rem;
		}

		@include media-breakpoint-down(lg) {
			margin-top: 56px;
			padding-top: 3rem;
			padding-bottom: 3rem;
		}
	}
</style>